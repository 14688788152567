import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Página não encontrada | Iqara Islam" />
    <div className="not-found">
      <h1>404</h1>
      <p>Ops, página não encontrada!</p>
      <span>
        Use nosso buscador para encontrar o que deseja. Se achar que algo está
        errado, entre em{" "}
        <Link className="contact-us-link" to="/contato">
          contato conosco
        </Link>
        .
      </span>
    </div>
  </Layout>
)

export default NotFoundPage
